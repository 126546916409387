export default [
    {
        path: '/',
        name: 'site-home',
        component: () => import('@/views/site/SiteHome.vue'),
        meta: {
            layout: 'full',
            resource: 'any',
        },
    },
    {
        path: '/blog/:slug',
        name: 'blog-details',
        component: () => import('@/views/empresa/blog/Details.vue'),
        meta: {
            pageTitle: 'Blog',
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/blog',
        name: 'blog',
        component: () => import('@/views/empresa/blog/List.vue'),
        meta: {
            pageTitle: 'Blog',
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/lavarapido',
        name: 'site-home-lavarapido',
        component: () => import('@/views/site/SiteHome.vue'),
        meta: {
            layout: 'full',
            resource: 'any',
            pageTitle: 'Lava-rápido',
            entity: 'Lava-rápido',
        },
    },
        {
        path: '/lavajato',
        name: 'site-home-lavajato',
        component: () => import('@/views/site/SiteHome.vue'),
        meta: {
            layout: 'full',
            resource: 'any',
            pageTitle: 'Lava Jato',
            entity: 'Lava Jato',
        },
    },
    {
        path: '/esteticaautomotiva',
        name: 'site-home-esteticaautomotiva',
        component: () => import('@/views/site/SiteHome.vue'),
        meta: {
            layout: 'full',
            resource: 'any',
            pageTitle: 'Estética Automotiva',
            entity: 'Estética Automotiva',
        },
    },
    {
        path: '/mecanica',
        name: 'site-home-mecanica',
        component: () => import('@/views/site/SiteHome.vue'),
        meta: {
            layout: 'full',
            resource: 'any',
            pageTitle: 'Mêcanica',
            entity: 'Mêcanica',
        },
    },
    {
        path: '/chapeacao',
        name: 'site-home-chapeacao',
        component: () => import('@/views/site/SiteHome.vue'),
        meta: {
            layout: 'full',
            resource: 'any',
            pageTitle: 'Chapeação',
            entity: 'Chapeação',
        },
    },
    {
        path: '/funilaria',
        name: 'site-home-funilaria',
        component: () => import('@/views/site/SiteHome.vue'),
        meta: {
            layout: 'full',
            resource: 'any',
            pageTitle: 'Funilaria',
            entity: 'Funilaria',
        },
    },
    {
        path: '/borracharia',
        name: 'site-home-borracharia',
        component: () => import('@/views/site/SiteHome.vue'),
        meta: {
            layout: 'full',
            resource: 'any',
            pageTitle: 'Borracharia',
            entity: 'Borracharia',
        },
    }
]
